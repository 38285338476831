/*** FoundPad Application confirmations */
import * as UserApplication from '../models/userApplication';


// Get the confirm buttons id
var confirmEv2019 = document.getElementById('confirmEv2019');
var confirmF619 = document.getElementById('confirmF619');
// When the user clicks on the confirm button, set EV2019 confirm boolean to true 
confirmEv2019.onclick = function() {
    const confirmData = {
    confirmedEv2019 : true
    };
    UserApplication.update(confirmData)
    .then(() => {
    // Disable button
    document.getElementById('confirmEv2019').innerHTML = 'Application Confirmed';
    document.getElementById('confirmEv2019').removeAttribute('href');
    document.getElementById('confirmEv2019').style.pointerEvents="none";
    document.getElementById('confirmEv2019').style.cursor="default";
    document.getElementById('confirmEv2019').style.opacity="0.7";
    //Handle success feedback
    console.log('[INFO] successfully confirmed the EV2019 application!');
    alert('You just confirmed your application to Emerging Valley 2019');
    })
    .catch(error => {
    // Handle error feedback
    console.log(`[ERROR] failed in confirming the EV2019 application :\n${error.message}`,);
    });
}
// When the user clicks on the confirm button, set F619 confirm boolean to true 
confirmF619.onclick = function() {
    const confirmData = {
    confirmedF619 : true
    };
    UserApplication.update(confirmData)
    .then(() => {
    // Disable button
    document.getElementById('confirmF619').innerHTML = 'Application Confirmed';
    document.getElementById('confirmF619').removeAttribute('href');
    document.getElementById('confirmF619').style.pointerEvents="none";
    document.getElementById('confirmF619').style.cursor="default";
    document.getElementById('confirmF619').style.opacity="0.7";
    //Handle success feedback
    console.log('[INFO] successfully confirmed the F619 application!');
    alert('You just confirmed your application to Investment Readiness Bootcamp by Factory 619');
    })
    .catch(error => {
    // Handle error feedback
    console.log(`[ERROR] failed in confirming the F619 application :\n${error.message}`,);
    });
}

