const db = firebase.firestore();
const auth = firebase.auth();

export const getById = () => {
  // TODO implement getById helper function
};

export const getAll = () => {
  // TODO implement getCollection helper function
};

export const create = payload => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;

    if (user) {
      console.log(
        `[INFO] now attempting application form creation for user: ${
          user.uid
        }.`,
      );

      // Handle create action
      const docRef = db.collection('users').doc(user.uid);
      docRef
        .set(payload)
        .then(resolve)
        .catch(reject);
    } else {
      reject(new Error('User is not logged in!'));
    }
  });
};

/**
 * Takes user application or profile and updates firestore /users/{uid}
 * with the new data
 * @param {*} payload
 * @returns {Promise}
 */
export const update = payload => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;

    if (user) {
      console.log(
        `[INFO] now attempting application form update for user: ${user.uid}.`,
      );

      // Handle update action
      const docRef = db.collection('users').doc(user.uid);
      docRef
        .update(payload)
        .then(resolve)
        .catch(reject);
    } else {
      reject(new Error('User is not logged in!'));
    }
  });
};

export const remove = () => {
  // TODO implement deleteDocument helper function
};

/**
 * Uploads a pitchdeck to firebase storage ref /{uid}/pitchdecks
 * @param {File} file
 * @returns {Promise}
 */
export const uploadPitchdeck = file => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;

    if (user) {
      console.log(
        `[INFO] now attempting to upload file from the user's application form for: ${
          user.uid
        }.`,
      );

      // Create a root reference
      const storageRef = firebase.storage().ref();

      // Get file extention
      const fileName = file.name;
      const fileExt =
        fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
        fileName;

      // Create a reference to '{uid}/pitchdecks/{timestamp}.{ext}'
      const fileRef = storageRef.child(
        `${user.uid}/pitchdecks/${Date.now()}.${fileExt}`,
      );

      // Handle action
      fileRef
        .put(file)
        .then(fileSnapshot => {
          // Handle success feedback
          console.log('[INFO] successfully uploaded the file!');

          // Get file download Url to append it to the user application form
          fileSnapshot.ref
            .getDownloadURL()
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    } else {
      reject(new Error('User is not logged in!'));
    }
  });
};
